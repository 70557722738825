<template>
  <v-app id="uva-testing-auth" :style="{ backgroundColor: navy }">
    <v-container>
      <v-row class="d-flex justify-center align-center" style="height: 100vh">
        <v-col cols="12" sm="5" md="4">
          <div class="text-center text-h4 mb-2">UVA TESTING</div>
          <v-card elevation="0" :color="uva_green">
            <v-toolbar dense flat :color="neon_green">
              <v-toolbar-title>
                <span v-if="stage == 2">Change Password</span>
                <span v-else>Login</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items class="mr-n4">
                <v-tooltip left color="green darken-4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="green darken-4"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      @click="reset"
                    >
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Reset</span>
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>

            <!-- ----- -->
            <!-- ERROR -->
            <!-- ----- -->
            <v-expand-transition>
              <v-row v-if="error">
                <v-col>
                  <v-list>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon large color="error">mdi-alert-circle</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <div class="error--text text-darken-1">
                          {{ error }}
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-expand-transition>

            <!-- STAGE 00 -->
            <!-- --------- -->
            <template v-if="stage == 0">
              <AuthStage00 @credentials="sendCredentials" />
            </template>

            <!-- STAGE 01 -->
            <!-- -------- -->
            <template v-if="stage == 1">
              <AuthStage01 @submit="submitOTP" />
            </template>

            <!-- STAGE 02 / CHANGE PASSWORD -->
            <!-- -------------------------- -->
            <template v-if="stage == 2">
              <AuthStage02 @changePassword="getNewPassword" />
            </template>

            <!-- ------------------------------------- -->
            <!-- STAGE 03 / CHANGE PASSWORD - RESPONSE -->
            <!-- ------------------------------------- -->
            <template v-if="stage == 3">
              <AuthStage03 :response="response" />
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useAuthStore } from "@/store/auth";
import { useColorsStore } from "@/store/colors";
import { useStorageStore } from "@/store/storage";
import AuthStage00 from "./comps/AuthStage00.vue";
import AuthStage01 from "./comps/AuthStage01.vue";
import AuthStage02 from "./comps/AuthStage02.vue";
import AuthStage03 from "./comps/AuthStage03.vue";

export default {
  name: "LoginView",
  components: {
    AuthStage00,
    AuthStage01,
    AuthStage02,
    AuthStage03,
  },
  data() {
    return {
      error: null,
      response: null,
    };
  },
  created() {
    this.getUVAToken();
  },
  computed: {
    ...mapState(useAuthStore, [
      "stage",
      "credentials",
      "otp",
      "emailForChangePassword",
    ]),
    ...mapState(useColorsStore, [
      "navy",
      "neon_green",
      "uva_green",
      "med_green",
    ]),
  },
  methods: {
    ...mapActions(useAuthStore, [
      "validateCredentials",
      "resetState",
      "validateOTP",
      "routeTo",
      "changePassword",
    ]),
    ...mapActions(useStorageStore, ["storeUVAToken", "getUVAToken"]),
    async sendCredentials() {
      this.error = null;
      try {
        await this.validateCredentials(this.credentials);
      } catch (error) {
        error.status == 422
          ? (this.credentials.errors = error.data.errors)
          : this.setError(error);
      }
    },
    async submitOTP() {
      // console.log('submit_otp')
      this.error = null;
      try {
        const result = await this.validateOTP();
        // console.log({result})
        this.storeUVAToken(result);
        this.routeTo();
        this.reset();
      } catch (error) {
        error.status == 422
          ? (this.otp.errors = error.data.errors)
          : this.setError(error);
      }
    },
    async getNewPassword() {
      this.error = null;
      try {
        const response = await this.changePassword();
        this.response = response;
      } catch (error) {
        error.status == 422
          ? (this.emailForChangePassword.errors = error.data.errors)
          : this.setError(error);
      }
    },
    setError(error) {
      this.error = error.data;
    },
    reset() {
      this.resetState();
      this.error = null;
    },
  },
};
</script>

<style>
.theme--dark.v-otp-input .v-input .v-input__control .v-input__slot {
  background: #294534;
}
</style>