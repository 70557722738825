// const BASE_URL = "http://127.0.0.1:8000/api";
const BASE_URL = process.env.VUE_APP_API_URL;

const composeUrl = (url, params = false) => {
    if (!params) return `${BASE_URL}${url}`;
    if (Array.isArray(params)) return handleMultipleParams(url, params);
    return `${BASE_URL}${url}/${params}`;
};

const handleMultipleParams = (url, params) => {
    if (url.includes('{') && url.includes('}')) {
        const regex = /({[\w:]+})/g;
        const match_array = url.match(regex)
        for (let i = 0; i < match_array.length; i++) {
            url = url.replace(match_array[i], params[i])
        }

        return `${BASE_URL}${url}`
    }

    return `${BASE_URL}${url}/${params.join('/')}`
}

export {
    BASE_URL,
    composeUrl,
}