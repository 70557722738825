import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        notification: {
            state: 'info',
            show: false,
            message: ""
        },
        confirmDialog: {
            show: false,
            message: ""
        }
    }),
    getters: {},
    actions: {
        showNotification(message, state) {
            this.notification = {
                state: state ?? 'info',
                show: true,
                message,
            }
        },
        showGenericNotification(message) {
            this.notification = {
                state: 'info',
                show: true,
                message,
            }
        },
        showSuccessNotification(message) {
            this.notification = {
                state: 'success',
                show: true,
                message,
            }
        },
        showErrorNotification(message) {
            this.notification = {
                state: 'error',
                show: true,
                message,
            }
        },
        showConfirmationDialog(message) {
            if (typeof message == "boolean") {
                this.confirmDialog = {
                    show: false,
                    message: null
                }
                return;
            }

            this.confirmDialog = {
                show: true,
                message,
            }
        }
    },
})
