import axios from 'axios';
import { defineStore } from 'pinia'
import { composeUrl } from '@/utils/axios-utils';
import ls from 'localstorage-slim'
import { useStorageStore } from './storage';
import { format, fromUnixTime } from 'date-fns'
import jwt_decode from "jwt-decode";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        credentials: {
            email: null,
            password: null,
            errors: {}
        },
        emailForChangePassword: {
            email: null,
            errors: {}
        },
        stage: 0,
        otp: {
            otp: null,
            errors: {},
        },
        user: null,
        pushTo: [
            { to: 'admin-tests', roles: ['super_admin', 'admin', 'testing_dept'] },
            { to: 'admin-test-tests', roles: ['site_coordinator', 'test_admin', 'hall_monitor', 'principal'] },
            { to: 'student-tests', roles: ['student'] },
        ],
        roles: ls.get('uva_roles', { decrypt: true }),
        token: ls.get('uva_token', { decrypt: true })
    }),
    getters: {},
    actions: {
        async validateCredentials() {
            try {
                await axios.post(composeUrl('/auth/credentials'), this.credentials);
                this.stage = 1;
            } catch (error) {
                return Promise.reject(error.response);
            }
        },
        async validateOTP() {
            try {
                const result = await axios.post(composeUrl('/auth/otp'), { ...this.otp, ...this.credentials })
                return Promise.resolve(result.data);
            } catch (error) {
                return Promise.reject(error.response);
            }
        },
        async changePassword() {
            try {
                const result = await axios.post(composeUrl('/auth/change/password'), { email: this.emailForChangePassword.email })
                this.stage = 3;
                return Promise.resolve(result.data)
            } catch (error) {
                return Promise.reject(error.response);
            }
        },
        async getRoles() {
            if (this.roles) return;

            let token = ls.get('uva_token', { decrypt: true })
            try {
                const result = await axios.get(composeUrl('/roles'), {
                    headers: {
                        'Authorization': `Bearer ${token.access_token}`
                    }
                })

                ls.set('uva_roles', result.data, { encrypt: true, ttl: 3600 })

                this.roles = result.data;
            } catch (error) {
                console.log(error);
            }
        },
        async newPassword(form, sr) {
            try {
                const result = await axios.post(composeUrl('/auth/store/new/password'), { ...form, signed_request_token: sr });
                return Promise.resolve(result.data);
            } catch (error) {
                return Promise.reject(error.response);
            }
        },
        async logOut() {
            let token = ls.get('uva_token', { decrypt: true });
            try {
                await axios.post(composeUrl('/auth/logout'), {}, {
                    headers: {
                        'Authorization': `Bearer ${token.access_token}`
                    }
                })

                ls.clear();
            } catch (error) {
                console.log(error.response);
            }
        },
        async extendSession() {
            let token = ls.get('uva_token', { decrypt: true });
            const storage = useStorageStore();

            try {
                const result = await axios.post(composeUrl('/auth/refresh'), {}, {
                    headers: {
                        'Authorization': `Bearer ${token.access_token}`
                    }
                });

                storage.storeUVAToken(result.data);
                ls.remove('uva_five_min_warning');
                ls.remove('uva_thirty_sec_warning');
                const { exp } = jwt_decode(result.data.access_token);
                window.location.reload();
                return Promise.resolve({ expires_at: format(fromUnixTime(exp), 'MMM dd, yyyy HH:mm:ss') })
            } catch (error) {
                return Promise.reject(error.response)
            }
        },
        resetState() {
            this.credentials = {
                email: null,
                password: null,
                errors: {}
            }
            this.stage = 0;
            this.otp = {
                otp: null,
                errors: {},
            };
            this.emailForChangePassword = {
                email: null,
                errors: {}
            }
        },
        routeTo() {
            if (!this.user) return;
            const path = this.pushTo.find(path => path.roles.includes(this.user.user_role));
            this.router.push({ name: path.to });
        }
    },
})
