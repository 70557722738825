<template>
  <div>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              filled
              label="Enter Account Email"
              v-model="emailForChangePassword.email"
              :hide-details="!emailForChangePassword.errors.email"
              :error-messages="emailForChangePassword.errors.email"
              @focus="emailForChangePassword.errors = {}"
              @keyup.enter="getNewPassword"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="green lighten-3 green--text text--darken-4"
        @click.stop="getNewPassword"
      >
        <v-icon left>mdi-lock-reset</v-icon>
        Submit
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapWritableState } from "pinia";
import { useAuthStore } from "@/store/auth";
export default {
  name: "AuthStage02",
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapWritableState(useAuthStore, ["emailForChangePassword"]),
  },
  methods: {
    async getNewPassword() {
      this.$emit('changePassword');
    },
  },
};
</script>