<template>
  <div>
    <v-card-text>
      <v-container>
        <!-- ----- -->
        <!-- EMAIL -->
        <!-- ----- -->
        <v-row>
          <v-col>
            <v-text-field
              dark
              filled
              type="email"
              label="Email"
              color="green accent-3"
              v-model="credentials.email"
              :hide-details="!credentials.errors.email"
              :error-messages="credentials.errors.email"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- -------- -->
        <!-- PASSWORD -->
        <!-- -------- -->
        <v-row>
          <v-col>
            <v-text-field
              dark
              filled
              label="Password"
              type="password"
              color="green accent-3"
              v-model="credentials.password"
              :hide-details="!credentials.errors.password"
              :error-messages="credentials.errors.password"
              @keyup.enter="sendCredentials"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-tooltip top color="green darken-4">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="stage = 2"
          >
            <v-icon>mdi-lock-reset</v-icon>
            CP
          </v-btn>
        </template>
        <span>Change Password</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn
        color="green lighten-3 green--text text--darken-4"
        @click.stop="sendCredentials"
      >
        Submit
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapWritableState } from "pinia";
import { useAuthStore } from "@/store/auth";
export default {
  name: "AuthStage00",
  props: {},
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapWritableState(useAuthStore, ["credentials", "stage"]),
  },
  methods: {
    sendCredentials() {
      this.$emit("credentials");
    },
  },
};
</script>