import ls from 'localstorage-slim'
import jwtdecoder from 'jwt-decode'

const userRole = () => {
    const token = ls.get('uva_token', {decrypt: true});
    if(!token) return null;

    const {user_role} = jwtdecoder(token.access_token);
    return user_role;
}

export {
    userRole
}