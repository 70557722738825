<template>
  <v-dialog
    v-model="thirtySecDialog"
    width="500"
    persistent
    overlay-color="#611f22"
    overlay-opacity=".95"
  >
    <v-card color="#c23d44">
      <v-alert tile color="#411417" class="text-body-1" v-if="error">
        <span class="red--text font-weight-bold">Error:</span>
        {{ error }}
      </v-alert>
      <v-card-text>
        <v-container>
          <v-row style="margin-top: 1em">
            <v-col class="text-center">
              <span
                class="text-body-1 font-weight-bold"
                style="font-size: 1.4rem !important; color: #ebbec1"
              >
                Your session is about to <span>expire</span>.
              </span>
            </v-col>
          </v-row>
          <v-row style="margin-top: 3em">
            <v-col class="text-center">
              <span style="font-size: 6rem">{{ showCountDown }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <span class="text-body-1">
                Click the
                <span style="color: #611f22; font-weight: bold">EXTEND</span>
                button to add an additional 60 minutes to your session.
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="#611f22" @click="logOut">
          <v-icon left>mdi-logout-variant</v-icon>
          Logout
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="#611f22" @click="extend">
          <v-icon left>mdi-refresh</v-icon>
          extend
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapWritableState } from "pinia";
import { useIndexStore } from "@/store";
import { useAuthStore } from "@/store/auth";
export default {
  name: "ThirtySecondWarningDialog",
  data() {
    return {
      countDown: 30,
      error: null,
    };
  },
  mounted() {
    setInterval(() => this.updateCountDown(), 1000);
  },
  computed: {
    ...mapWritableState(useIndexStore, ["thirtySecDialog"]),
    showCountDown() {
      return String(this.countDown).padStart(5, "00:00");
    },
  },
  methods: {
    ...mapActions(useAuthStore, ["extendSession", "logOut"]),
    updateCountDown() {
      if (this.countDown > 0) {
        this.countDown -= 1;
      }
    },
    async extend() {
      this.error = null;
      try {
        await this.extendSession();
        this.thirtySecDialog = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>