<template>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col>
          <v-alert
            color="green lighten-4 green--text text--darken-3"
            class="mb-n1"
          >
            {{ response }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
export default {
  name: "AuthStage03",
  props: { response: String },
  data() {
    return {
      //
    };
  },
};
</script>