import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '@/views/Auth/LoginView.vue'
import { userRole } from '@/utils/ls-utils'
// import axios from 'axios'
// import { composeUrl } from '@/utils/axios-utils'
// import AttendanceCheckIn from '@/views/AdminTest/Attendance/AttendanceCheckIn'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/change/password/',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/Auth/ChangePasswordView'),
    props: route => ({ sr: route.query.sr })
  },
  {
    path: '/tests',
    component: () => import(/* webpackChunkName: "admin-layout" */ '@/views/Admin/AdminLayoutView'),
    children: [
      {
        path: '/',
        name: 'admin-tests',
        component: () => import(/* webpackChunkName: "admin-tests" */ '@/views/Admin/Tests/TestsView'),
        meta: {
          displayName: "Tests",
          allowedRoles: ['super_admin', 'admin', 'testing_dept']
        },
      },
      {
        path: '/tests/:slug',
        name: 'admin-tests-single',
        component: () => import(/* webpackChunkName: "admin-tests-single" */ '@/views/Admin/Tests/TestView'),
        meta: {
          displayName: "",
          back: true,
          allowedRoles: ['super_admin', 'admin', 'testing_dept']
        },
      },
      {
        path: '/users',
        name: 'admin-users',
        component: () => import(/* webpackChunkName: "admin-users" */ '@/views/Admin/Users/UsersView'),
        meta: {
          displayName: "Users",
          allowedRoles: ['super_admin', 'admin', 'testing_dept']
        },
      },
      {
        path: '/users/:slug',
        name: 'admin-users-profile',
        component: () => import(/* webpackChunkName: "admin-users-profile" */ '@/views/Admin/Users/UsersProfileView'),
        meta: {
          displayName: "Profile",
          back: true,
          allowedRoles: ['super_admin', 'admin', 'testing_dept']
        }
      }
    ],
  },
  {
    path: '/student',
    component: () => import(/* webpackChunkName: "student-layout" */ '@/views/Students/StudentLayoutView'),
    children: [
      {
        path: '/',
        name: 'student-tests',
        component: () => import(/* webpackChunkName: "student-tests" */ '@/views/Students/Dashboard/StudentTestsView'),
        meta: {
          displayName: "Student Dashboard",
          allowedRoles: ['student']
        },
      },
    ]
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "student-layout" */ '@/views/AdminTest/AdminTestLayoutView'),
    children: [
      {
        path: '/',
        name: 'admin-test-tests',
        component: () => import(/* webpackChunkName: "admin-test-tests" */ '@/views/AdminTest/Dashboard/AdminTestTestsView'),
        meta: {
          displayName: "Admin Dashboard",
          allowedRoles: ['site_coordinator', 'test_admin', 'hall_monitor', 'principal']
        },
      },
      {
        path: ':admin_test_uuid/check-in',
        name: 'admin-test-check-in',
        component: () => import(/* webpackChunkName: "admin-test-check-in" */ '@/views/AdminTest/Dashboard/AdminTestCheckInView'),
        // component: () => import(/* webpackChunkName: "admin-test-check-in" */ '@/views/AdminTest/Attendance/AttendanceCheckIn'),
        meta: {
          displayName: "Check-in",
          back: true,
          allowedRoles: ['site_coordinator', 'test_admin', 'hall_monitor', 'principal']
        },
      },
      {
        path: ':admin_test_uuid/check-out',
        name: 'admin-test-check-out',
        component: () => import(/* webpackChunkName: "admin-test-check-out" */ '@/views/AdminTest/Dashboard/AdminTestCheckOutView'),
        meta: {
          displayName: "Check-out",
          back: true,
          allowedRoles: ['site_coordinator', 'test_admin', 'hall_monitor', 'principal']
        },
      },
    ]
  },
  {
    path: "*",
    component: () => import(/* webpackChunkName: "404" */ '@/views/Error/NotFound')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const role = userRole();
  if (to.name == 'login' && role !== null) {
    if (['super_admin', 'admin', 'testing_dept'].includes(role)) next({ name: 'admin-tests' });
    if (['student'].includes(role)) next({ name: 'student-tests' });
    if (['site_coordinator', 'test_admin', 'hall_monitor', 'principal'].includes(role)) next({ name: 'admin-test-tests' });
  }

  if (['admin-users', 'admin-tests', 'admin-users-profile', 'admin-tests-single'].includes(to.name) && !to.meta.allowedRoles.includes(role)) next({ name: 'login' })
  if (['student-tests'].includes(to.name) && !to.meta.allowedRoles.includes(role)) next({ name: 'login' })
  if (['admin-test-tests', 'admin-test-check-in', 'admin-test-check-out'].includes(to.name) && !to.meta.allowedRoles.includes(role)) next({ name: 'login' })
  else next();
})

export default router
