import { composeUrl } from '@/utils/axios-utils';
import axios from 'axios';
import { defineStore } from 'pinia'
import ls from 'localstorage-slim'
import { useAuthStore } from './auth';
import jwt_decode from 'jwt-decode';
import { isAfter, subMinutes, subSeconds, fromUnixTime } from 'date-fns'

export const useIndexStore = defineStore('index', {
  state: () => ({
    token: ls.get('uva_token', { decrypt: true }),
    counter: null,
    directEmailDialog: false,
    directEmailRecipient: null,
    directEmailColors: {
      primary: "grey darken-3",
      secondary: "grey darken-1",
      tertiary: "grey darken-1",
    },
    willExpireDialog: false,
    hasExpiredDialog: false,
    title: null,
    loginUrl: '',
    fiveMinDialog: false,
    thirtySecDialog: false,
  }),
  getters: {},
  actions: {
    setEmailDialog(state) {
      this.directEmailDialog = state;
    },
    resetDirectEmail() {
      this.directEmailDialog = false;
      this.directEmailRecipient = null;
      this.directEmailColors = {
        primary: "grey darken-3",
        secondary: "grey darken-1",
        tertiary: "grey darken-1",
      };
    },
    runCounter() {
      this.counter = setInterval(() => this.checkIfExpired(), 2000);
    },
    checkIfExpired() {
      if (!ls.get('uva_token', { decrypt: true })) {
        ls.remove('uva_five_min_warning');
        ls.remove('uva_thirty_sec_warning');
        clearInterval(this.counter);
        window.location.replace(window.location.origin);
        return;
      }

      const { exp } = jwt_decode(ls.get('uva_token', { decrypt: true }).access_token);

      //Once we're at the last five minutes, show the last five minute warning only once.
      if (isAfter(new Date(), subMinutes(fromUnixTime(exp), 5)) &&
        !ls.get('uva_five_min_warning', { decrypt: true })
      ) {
        this.fiveMinDialog = true;
        ls.set('uva_five_min_warning', true, { encrypt: true })
        return;
      }

      //Once we're at the last thirty seconds, show the last thirty second warning .
      if (isAfter(new Date(), subSeconds(fromUnixTime(exp), 30)) &&
        !ls.get('uva_thirty_sec_warning', { decrypt: true })) {
        this.thirtySecDialog = true;
        ls.set('uva_thirty_sec_warning', true, { encrypt: true })
        return;
      }
    },
    async sendDirectEmail(form) {
      const auth = useAuthStore();

      const submission = {
        ...form,
        from: auth.user,
        recipient: this.directEmailRecipient
      }

      try {
        const result = await axios.post(composeUrl('/email/directly', this.directEmailRecipient.uuid), submission, {
          headers: {
            Authorization: `Bearer ${this.token.access_token}`
          }
        });

        return Promise.resolve(result.data);
      } catch (error) {
        return Promise.reject(error.response)
      }
    },
    downloadBlob(blob, name = 'file.csv') {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    },
  },
})
