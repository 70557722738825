import { defineStore } from 'pinia'
import {useAuthStore} from './auth'
import ls from 'localstorage-slim'
import jwt_decode from "jwt-decode";
import {
    format, 
    // parseISO, 
    fromUnixTime,
    // subMinutes, 
    // isAfter
} from 'date-fns'

export const useStorageStore = defineStore('storage', {
    state: () => ({

    }),
    getters: {},
    actions: {
        storeUVAToken(value){
            ls.set('uva_token', value, { ttl: 3600, encrypt: true });
            this.decodeJWT(value.access_token);
        },
        getUVAToken(){
            const token = ls.get('uva_token', {decrypt: true});
            if(token){
                this.decodeJWT(token.access_token)
            }
            return token;
        },
        removeUVAToken(){
            ls.remove('uva_token')
            const auth = useAuthStore();
            auth.user = null;
        },
        decodeJWT(token){            
            try {
                const {user_name, user_id, user_role, exp} = jwt_decode(token);
                console.log({now: format(new Date(), "HH:mm:ss"), end: format(fromUnixTime(exp), 'HH:mm:ss')}) //MMM dd, yyyy 
                const auth = useAuthStore();
                auth.user = {user_name, user_id, user_role, expire_at:exp};
            } catch (error) {
                // console.log(error.message)
            }
        }
    },
})
