import Vue from 'vue';
import colors from 'vuetify/lib/util/colors'
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { 
        dark: true,
        themes: {
            // light: {
            //   primary: colors.purple,
            //   secondary: colors.grey.darken1,
            //   accent: colors.shades.black,
            //   error: colors.red.accent3,
            // },
            dark: {
              primary: colors.green.lighten3,
              secondary: colors.blue.lighten2,
              error: colors.shades.white,
            },
        }
    },
});
