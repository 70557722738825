<template>
  <v-app>
    <router-view />

    <FiveMinuteWarningDialog />
    <ThirtySecondWarningDialog v-if="thirtySecDialog"/>
  </v-app>
</template>

<script>
import { mapState } from 'pinia';
import FiveMinuteWarningDialog from "./components/Dialogs/FiveMinuteWarningDialog.vue";
import ThirtySecondWarningDialog from './components/Dialogs/ThirtySecondWarningDialog.vue';
import { useIndexStore } from './store';
export default {
  name: "App",
  components: {
    FiveMinuteWarningDialog,
    ThirtySecondWarningDialog,
  },
  created() {},
  data() {
    return {
      //
    };
  },
  computed:{
    ...mapState(useIndexStore, ["thirtySecDialog"])
  }
};
</script>

<style>
</style>