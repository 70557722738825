<template>
  <v-snackbar light color="#12304b" v-model="fiveMinDialog" multi-line width="500" timeout="10000">
    <span class="text-body-1 font-weight-medium white--text">
      Your session will be expiring in <span style="color: #00c44f;">five (5)</span> minutes.
      Click <span style="color: #00c44f;">EXTEND</span> to add an additional 60 minutes to your session.
    </span>
    <template v-slot:action="{ attrs }">
      <v-btn color="#00c44f" depressed v-bind="attrs" @click="extend"> Extend </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapWritableState } from "pinia";
import { useIndexStore } from "@/store";
import { useAuthStore } from '@/store/auth';
import { useNotificationStore } from '@/store/notifications';
export default {
  name: "FiveMinuteWarningDialog",
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapWritableState(useIndexStore, ["fiveMinDialog"]),
  },
  methods:{
    ...mapActions(useAuthStore, ["extendSession"]),
    ...mapActions(useNotificationStore, ["showSuccessNotification"]),
    async extend(){
        this.fiveMinDialog = false;
        const result = await this.extendSession();
        this.showSuccessNotification(`Your session has been extended. Expires at: ${result.expires_at}`);
    }
  }
};
</script>