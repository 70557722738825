import Vue, { markRaw } from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/index'
import { PiniaVuePlugin } from 'pinia'
import VueCompositionAPI from '@vue/composition-api'
import Vuelidate from 'vuelidate'
import { VueMasonryPlugin } from 'vue-masonry';
import VueTransitions from '@morev/vue-transitions';
import '@morev/vue-transitions/styles';
import VueMask from 'v-mask'
import VueNumberInput from '@chenfengyuan/vue-number-input';
import VueQrcodeReader from "vue-qrcode-reader";
import LottieAnimation from 'lottie-web-vue';

import pinia from '@/plugins/store'
Vue.use(PiniaVuePlugin)

//Resource: https://stackoverflow.com/questions/73917194/how-to-use-vue-router-inside-a-pinia-store
pinia.use(({ store }) => {
  store.router = markRaw(router);
})

Vue.use(VueCompositionAPI)
Vue.use(Vuelidate)
Vue.use(VueMasonryPlugin)
Vue.use(VueMask)
Vue.use(VueNumberInput);
Vue.use(VueQrcodeReader);
Vue.use(LottieAnimation);
Vue.use(VueTransitions, {
  // Plugin options (optional, described below)
});

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')
