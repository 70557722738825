<template>
  <v-card-text>
    <v-container>
      <v-row>
        <v-col>
          <v-otp-input
            v-model="otp.otp"
            color="green"
            length="6"
            type="number"
            @finish="submitOTP"
            :hide-details="!otp.errors.otp"
            :error-messages="otp.errors.otp"
          ></v-otp-input>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useAuthStore } from "@/store/auth";
import { useColorsStore } from "@/store/colors";
export default {
  name: "AuthStage01",
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapWritableState(useAuthStore, ["otp"]),
    ...mapState(useColorsStore, ["dark_green"]),
  },
  methods: {
    ...mapActions(useAuthStore, ["validateOTP"]),
    submitOTP() {
      this.$emit("submit");
    },
  },
};
</script>