import { defineStore } from 'pinia'

export const useColorsStore = defineStore('colors', {
    state: () => ({
        //BLUE
        uva_blue: "#025EBA",
        dark_blue: "#011E3B",
        med_blue: "#014487",
        med_blue_light: "#3E82C7",
        navy: "#12263B",
        ashy_blue: "#2A5987",
        header_blue: "#16395A",

        //GREEN
        uva_green: "#007830",
        neon_green: "#00C44F",
        dark_green: "#00451C",
        med_green: "#3BC472",
        med_green_light: "#7CCE9D",
        hunter_green: "#154528",
        hunter_green_soft: "#2C9154",
        hunter_green_ashy: "#294534",
        pastel_green: "#7CCE9D",

        //BACKGROUND
        backgroundColor: '#000000'

    }),
    getters: {},
    actions: {
        setBackgroundColor(current_route) {
            const bgc = {
                'admin-tests': '#aad2ba',
                'admin-tests-single': '#aad2ba',
                'admin-users': '#7facda',
                'admin-users-profile': '#12273B',
                undefined: '#12273B',
                null: '#12273B'
            }[current_route];

            const elem = window.document.getElementById("app_color");
            elem.style.backgroundColor = bgc;

            this.backgroundColor = bgc;
        }
    },
})
